// import external dependencies
import 'jquery';

// Import everything from autoload
import './autoload/_bootstrap.js'

import Swiper, { Navigation, Pagination } from 'swiper';
Swiper.use([Navigation, Pagination]);

import Collapse from 'bootstrap.native/src/components/collapse-native.js';
import Tab 		from 'bootstrap.native/src/components/tab-native.js';

const mitelkaMenu = () => {
  const burger    = document.querySelector('.navbar-toggler');
  const overlay   = document.querySelector('.page-overlay');
  const close     = document.querySelector('.menu_close');
  const menuItems = document.querySelectorAll('.page-overlay .nav-link');

  for (let i = 0; i < menuItems.length; i++) {
    const el = menuItems.item(i);

    el.addEventListener('click', () => {
      overlay.classList.remove('active');
    });

  }
    
  burger.addEventListener('click', function() {
    overlay.classList.add('active');
  })

  close.addEventListener('click', function() {
    overlay.classList.remove('active');
  })
}

const mitelkaBg = () => {
	if(document.body.contains(document.querySelector('.mt_bg--auto'))){
		let bgDiv 	= document.querySelector('.mt_bg--auto');
		bgDiv.style.backgroundSize 	= 'auto '+(bgDiv.clientHeight - 250) + 'px';
	}
}

const mitelkaPackages = () => {
    if(document.body.contains(document.querySelector('.mt_carousel--packages'))){
		(function() {
			'use strict';
			const breakpoint = window.matchMedia( '(min-width:992px)' );
			let peIconsSwiper;

			const breakpointChecker = function() {
				if ( breakpoint.matches === true ) {
					if ( peIconsSwiper !== undefined ) peIconsSwiper.destroy( true, true );
						return;

				} else if ( breakpoint.matches === false ) {
					return enableSwiper();
				}
			};

			const enableSwiper = function() {
				// eslint-disable-next-line no-unused-vars
				peIconsSwiper = new Swiper('.mt_carousel--packages', {
					slidesPerView: 1,
					spaceBetween: 15,
					pagination: {
						el: '.mt_carousel--packages .mt_carousel-pagination',
						type: 'bullets',
					},
				});
			};

			breakpoint.addListener(breakpointChecker);
			breakpointChecker();
		})();
	}
}

const mitelkaChannelToggler = () => {
	if(document.body.contains(document.querySelector('[data-show-channels]'))) {
		let togglers 	= document.querySelectorAll('[data-show-channels]');
		for (let index = 0; index < togglers.length; index++) {
			const el = togglers[index];
			el.addEventListener('click', function(e) {
				e.preventDefault();
				let labelMore 	= e.target.getAttribute('data-show-channels-label-more');
				let labelLess 	= e.target.getAttribute('data-show-channels-label-less');
				let togglerId 	= e.target.getAttribute('data-show-channels');
				let toggledList = document.querySelector('[data-channels-id="'+togglerId+'"]');
				let elstoToggle = document.querySelectorAll('[data-channels-id="'+togglerId+'"] .mt_list-item--togglable');

				toggledList.classList.toggle('mt_list--opened');
		
				elstoToggle.forEach(el => {
					el.classList.toggle('mt_list-item--hidden');
				});

				if(e.target.innerHTML == labelMore) {
					e.target.innerHTML 	= labelLess;
				} else {
					e.target.innerHTML 	= labelMore;
				}
			})
		}
	}
}

const mitelkaAccordions = () => {
	if(document.body.contains(document.querySelector('.mt_accordion'))){

		var elementsToCollapse 	= document.querySelectorAll('[data-toggle="collapse"]');
		var collapseBodies 		= document.querySelectorAll('.mt_accordion-body');

		Array.from(elementsToCollapse).map(
			collapseTrigger => new Collapse(
				collapseTrigger,
			)
		)
		
		for (let index = 0; index < collapseBodies.length; index++) {
			const elBody = collapseBodies[index];

			elBody.addEventListener('show.bs.collapse', function(e){
				let card      = e.target.closest('.mt_accordion-item');
				card.classList.add('mt_accordion-item--active');
			}, false);
		
			elBody.addEventListener('hide.bs.collapse', function(e){
				let card      = e.target.closest('.mt_accordion-item');
				card.classList.remove('mt_accordion-item--active');
			}, false);      
		}
	}
}

const mitelkaChannelSwitch = () => {
	if(document.body.contains(document.querySelector('.mt_package-switcher'))){
		let swticherLinks 	= document.querySelectorAll('.mt_package-switcher-item');
		for (let index = 0; index < swticherLinks.length; index++) {
			const el = swticherLinks[index];
			el.addEventListener('click', function(e) {
				e.preventDefault();
				let tabId 	= e.target.getAttribute('data-tab-control');
				let tab 	= document.getElementById(tabId);
				tab.Tab.show();

				tab.scrollIntoView({
					behavior: 'smooth',
				});
			});
		}
	}
}

const mitelkaTabs = () => {
	if(document.body.contains(document.querySelector('.mt_tabs'))){
		var mtTabs 			= document.querySelector('[data-tabs]');
		var mtTabsLinks 	= mtTabs.getElementsByTagName('A');

		Array.from(mtTabsLinks).map(
			tab => new Tab( tab, {height: true} )
		);
	}
}

const initMitelka = () => {
	mitelkaMenu();
	mitelkaPackages();
	mitelkaBg();
	mitelkaAccordions();
	mitelkaTabs();
	mitelkaChannelToggler();
	mitelkaChannelSwitch();
}

document.addEventListener('DOMContentLoaded', () => {
	initMitelka();
});

window.addEventListener('resize', () => {
	mitelkaBg();
});
